<template>
  <div class="box">
    <img class="box_logo" src="../../assets/image/top-logo.png" alt="">
    <div class="container">
      <h1>RESPONSIBLE GAMEPLAY POLICY</h1>
      <p class="up_date" style="font-size: 14px;"><strong>DATED: 13/5/2024</strong></p>
      <span class="tit">SUNGARD BI-TECH INC. is committed to the protection of our players and promoting responsible social gameplay as a policy of customer care and social responsibility.</span>
      <ul class="ul_1">
        <li>
          <p><strong>SUPPORT ORGANISATIONS </strong></p>
          <ul>
            <li>If your gameplay may have had, or is at risk of having, a negative impact on your mental health, finances or relationships with friends or family, we encourage you to get in touch with the following help and support organizations:</li>
            <ul>
              <li>(a) Counseling Gaming Addicts Anonymous (GAA) is a fellowship of people who support each other in recovering from the problems resulting from excessive game playing.</li>
              <li>(b) Credit Counseling:</li>
              <ul>
                <li>(i) Financial Counseling Association of America (FCAA) is a professional association of financial counseling agencies that assist consumers with financial counseling services of all kinds, as well as debt management plans for the repayment of unsecured debts.</li>
                <li>(ii) National Foundation for Credit Counseling (NFCC) is one of the oldest networks of non-profit financial counseling agencies. The NFCC helps people to defeat their debt and look forward with confidence.</li>
              </ul>
              <li>(c) Please note that these organizations are independent support services and are NOT in any way affiliated with Game. They do NOT provide customer support or dispute resolution services.</li>
            </ul>
          </ul>
        </li>
        <li>
          <strong>TIPS</strong>
          <ul>
            <li>(a) Avoid gameplay while upset or emotional.</li>
            <li>
              (b) Take frequent breaks during your gameplay sessions.<br>
              
            </li>
            <li>(c) Avoid gameplay while intoxicated. </li>
            <li>(d) Avoid canceling redemptions. </li>
            <li>(e) Remember that gameplay is only a form of entertainment, it should not be seen as a source of income or an escape from reality.</li>
            <li>(f) Purchase only with money that you can afford to spend.</li>
            <li>(g) Set a budget and don ’t go over it.</li>
            <li>(h) Set a time limit before playing.</li>
            <li>(i) Understand how games work before playing and remember that the results are random.</li>
            <li>(j) Never let gameplay affect your employment, relationships, health or commitments.</li>
          </ul>
        </li>
        <li>
          <strong>PROTECTION</strong>
          <span class="tit">(a) Make sure that the decision to play on our Platform is your own personal choice and responsibility.</span>
          <span class="tit">(b) We do not recommend playing on our Platform if you:</span>
          <span class="tit">(i) are being treated or are in recovery for an addiction/dependency</span>
          <span class="tit">(ii) are under the influence of alcohol or any other substance</span>
          <span class="tit">(iii) are currently experiencing financial difficulty or a traumatic life event</span>
          <span class="tit">(iv) do not understand how to play the Games</span>
          <span class="tit">(v) have any mental health concerns, cognitive impairment or brain injury.</span>
        </li>
        <li>
          <strong>INTRODUCTION</strong>
          <span class="tit">This Responsible Gameplay Policy (RG Policy) describes the control tools, information and resources available to registered players on Game.</span>
          <span class="tit">This RG Policy forms part of the Game Terms and Conditions. Terms which are defined in the Terms and Conditions have the same meaning in this RG Policy.</span>
          <span class="tit">We may update the RG Policy at any time. Any amendments will be published on the Platform and such changes will be binding and effective immediately. </span>
          <span class="tit">Whenever we amend this RG Policy in a way that would limit your current rights or which may be detrimental, we will notify you upon your next visit to the Platform and you will be required to re-confirm your acceptance prior to playing any Games. If you do not agree to the amended RG Policy, you must stop using the Platform. </span>
        </li>

        <li>
          <strong>Game GRG Program</strong>
          <span class="tit">The Game Responsible Gameplay Program (GRG Program) is centered around our guiding principles of providing our customers with control tools, information and help resources needed to: </span>
          <ul>
            <li>Make an informed decision in gameplay</li>
            <li>Prevent problem gameplay from occurring on our site. </li>
            <li>The GRG Program is designed to support the needs of individuals at any stage of the customer journey and any level of gameplay. To do this, the GRG Program offers a range of player education, control tools and resources for professional help when needed. </li>
            <li>We also understands that it is a shared responsibility to achieve a fun and affordable gameplay environment and that it is ultimately an individual ’s choice to play. We do not provide counseling services nor do we police customer behavior. Instead, we focus on providing control tools and informing, educating and supporting informed decisions.</li>
            <li>We have well-trained staff available to assist you in relation to your gameplay. Our staff are encouraged and empowered to provide information and offer control tools proactively. </li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          
        </li>
        <li>
          <strong>RSG PLAY CONTROL TOOLS</strong>
          <span class="tit">Activity Reminders</span>
          <span class="tit">Game assists your play by providing an Activity Reminder every hour.</span>
          <span class="tit">The Activity Reminder:</span>
          <span class="tit">(i) suspends play and indicates how long you have been playing </span>
          <span class="tit">(ii) displays your play history since logging in</span>
          <span class="tit">(iii) allows you to end the gameplay session or continue playing.</span>
        </li>
        <li>
          <strong>Account History</strong>
          <span class="tit">Gameplay History Shows the result of each spin/hand played.</span>
          <span class="tit">Transaction History Shows your purchase and redemption history. </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RESPONSIBLEGAMEPLAYPOLICY'
}
</script>

<style lang="scss" scoped>
.box {
  background: #fff;
  width: 100%;
  padding-bottom: .4167rem;
  h1 {
    text-align: center;
    margin: .4167rem;
    color: #2fccbf;
  }
}

.container {
  width: 6.7708rem;
  margin: 0 auto;
  .up_date{
    margin-bottom: .2604rem;
  }
  span{
    font-size: 14px;
    line-height: .1563rem;
  }
  .tit{
    display: block;
    margin: .1042rem 0;
  }
}

.ul_1 {
  margin-top: .2604rem;
  list-style-type: upper-alpha;
  margin-left: -1em;
  line-height: .1823rem;
  font-size: 20px;
  .ul_2 {
    font-size: 14px;
    list-style-type: lower-alpha;
    text-align: left;
    line-height: .1302rem;
    padding-left: .026rem;
    margin: .0521rem 0;
    .ul_3{
      font-size: 14px;
      list-style-type: disc;
      text-align: left;
      line-height: .1302rem;
      padding-left: .0521rem;
      margin: .0521rem 0;
    }
  }
}
.box_logo{
  width: 200px;
  height: auto;
  position: relative;
  left: 2.0313rem;
  top: .5729rem;
}
@media (max-width: 1000px) {
  .box_logo{
    width: 80px;
    height: auto;
    position: relative;
    left: .1042rem;
    top: 30px;
  }
  .container{
    h1{
      font-size: 24px;
    }
    .tit{
      font-size: 1em;
      line-height: 30px;
    }
}
  .ul_1 {
    font-size: 10px;
    strong{
      line-height:20px;
    }
    .ul_2{
      line-height: 30px;
      padding-left: .0521rem;
      .ul_3{
        line-height: 30px;
        padding-left: .0521rem;
      }
    }
  }
}

</style>