<template>
    <div class="gameBady">
        <Header :message="'/game'"></Header>
        <div class="container-up">
            <div class="topbanner  wow animate__animated animate__rotateIn">
                <el-carousel ref="myCarousel" trigger="click" height="4.5313rem" arrow="never">
                    <el-carousel-item v-for="item in images" :key="item.url">
                        <img :src="item.url" class="bannerimg"
                            style="width: 8.8542rem; height: 4.5313rem; object-fit: cover" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <el-button-group class="topbannerbtn  wow animate__animated animate__rotateIn">
                <el-button icon="el-icon-arrow-left" @click="prevSlide"></el-button>
                <el-button type="warning" @click="nextSlide"><i
                        class="el-icon-arrow-right el-icon--right"></i></el-button>
            </el-button-group>
            <div class="container-down wow animate__animated animate__rollIn">
                <div class="boxList">
                    <h6 class="subtitle-frame">
                        All Products
                    </h6>
                    <div class="gameList">
                        <div class="game" v-for="item in gameList" :key="item.link">
                            <div class="border">
                                <img class="bigimg" :src="item.url" alt="">
                                <div style="display: flex; align-items: center; margin: .026rem .0521rem;">
                                    <img class="minimg" :src="item.imgUrl"  style="border: .0052rem solid #475669;">
                                    <span class="gameName">{{ item.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Game",
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            images: [
                { url: require("@/assets/image/Worldwide Blackjack-1.png") },
                { url: require("@/assets/image/Skyforged Titan-2.png") },
                { url: require("@/assets/image/The Chronicles of Mystic Kin-3.png") },
                { url: require("@/assets/image/Legacy of the Celestial Peaks-4.png") },
                { url: require("@/assets/image/Jelly Gem Journey-5.png") },
            ],
            gameList: [
                { url: require("@/assets/image/Worldwide Blackjack-1.png"),imgUrl:require('@/assets/image/Worldwide Blackjack-Icon.png'),link:'1',name:'Chilli Fiesta',},
                { url: require("@/assets/image/Skyforged Titan-2.png"),imgUrl:require('@/assets/image/Skyforged Titan-icon.png'),link:'2',name:'Skyforged Titan',},
                { url: require("@/assets/image/The Chronicles of Mystic Kin-3.png"),imgUrl:require('@/assets/image/The Chronicles of Mystic Kin-Icon.png'),link:'3',name:'The Chronicles of Mystic Kin',},
                { url: require("@/assets/image/Legacy of the Celestial Peaks-4.png"),imgUrl:require('@/assets/image/Legacy of the Celestial Peaks-icon.png'),link:'4',name:'Legacy of the Celestial Peaks',},
                { url: require("@/assets/image/Jelly Gem Journey-5.png"),imgUrl:require('@/assets/image/Jelly Gem Journey-icon.png'),link:'5',name:'Jelly Gem Journey',},
            ],
        }
    },
    mounted(){
        window.scrollTo(0,0);
    },
    methods: {
        prevSlide() {
            this.$refs.myCarousel.prev();
        },
        nextSlide() {
            this.$refs.myCarousel.next();
        }
    }
}
</script>

<style lang="scss" scoped>
.gameBady {
    background-color: #172532;
    padding-top: .3646rem;
}

.container-up {
    width: 8.8542rem;
    margin: 0 auto;
    position: relative;
}

.topbanner {
    margin: .026rem 0 .1563rem;
}

.topbannerbtn {
    position: absolute;
    top: 4.4167rem;
    right: .1042rem;
    z-index: 9;
}

.container-down {
    width: 8.8542rem;
    margin: 0 auto;
}

.boxList {
    background: #212f3c;
    border-radius: .0417rem;
    padding: .2083rem .3906rem;
    position: relative;
    z-index: 1;
    width: 95%;
    margin: .5208rem auto;
    h6 {
        font-size: .0938rem;
    }
}

.subtitle-frame {
    margin-left: -0.599rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    color: #ED9A21;
    position: relative;
    padding-left: .4688rem;
}

.subtitle-frame::after {
    content: "";
    position: absolute;
    width: .4167rem;
    height: .0052rem;
    background: #ED9A21;
    transform: translate(0, -50%);
    top: 50%;
    left: 0;
}
.gameList{
    width: 7.8281rem;
    padding: .2083rem .2344rem .2083rem .0677rem;
    margin: .2604rem .1849rem;
    display: flex;
    flex-wrap: wrap;
    .game{
        width: 2.2448rem;
        padding: .0521rem;
        position: relative;
        background: #172532;
        border: .0052rem solid rgba(255, 255, 255, 0.1);
        box-shadow: 0rem .0208rem .0208rem rgba(0, 0, 0, 0.1);
        border-radius: .0417rem;
        padding: .0521rem 0 .026rem; 
        margin: .0521rem;
        .border{
            img{
                margin-left: .0521rem;
            }
        }
    }
}
.gameName{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-left: .1042rem;
    color: #FFF;
}
.bigimg{
        width: 2.1354rem;
        height: 1.0417rem;
}
.minimg{
    width: .2083rem;
    height: .2083rem;
}
</style>