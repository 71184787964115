<template>
  <div class="box">
    <img class="box_logo" src="../../assets/image/top-logo.png" alt="">
    <div class="container">
      <h1>Game Statement</h1>
      <p class="up_date" style="font-size: 14px;"><strong>DATED: 13/5/2024</strong></p>
      <p class="up_tit">This Game Statement is provided by SUNGARD BI-TECH INC. (collectively referred to herein as "the team", "we", "our" or "us").</p>
      <p class="statement">This statement covers all games and services offered by our gaming team. The main content of this statement is where we offer the Game and who can play it. You must understand the contents of this statement. If you do not agree with this statement, please do not use the Game, or any of our services.</p>
      <ul class="ul_1">
        <ul class="ul_2">
          <ul class="ul_3">
            <li>
              <strong style="line-height: 40px;">Who can play the game?</strong>
              <ul style="list-style-type: none;">
                <li>IN USA AND CANADA:</li>
                <li>All game players must be at least 18 years old and citizen of the United States or Canada.</li>
              </ul>
            </li>
            <li>
              <strong style="line-height: 40px;">Where do we offer the real prize competitions?</strong>
              <ul style="list-style: none;">
                <li>IN USA AND CANADA:
                    We offer our games and services only in the United States or Canada.
                    We do not offer games in the following excepted states: 
                    USA: (1) Washington; (2) Idaho. 
                    CANADA: (1) Quebec.
                    The remaining states are all legal states.
                    We use Apple or Android location systems to determine if a player can play the game based on the GPS service of the player's mobile device.
                    However, we cannot guarantee that all users will be accurately identified. You must ensure that you fully comply with all applicable state laws when you use our games or services.
                </li>
              </ul>
            </li>
          </ul>
        </ul>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GAMESTATEMENT'
}
</script>

<style lang="scss" scoped>
.box {
  background: #fff;
  width: 100%;
  padding-bottom: .4167rem;
  h1 {
    text-align: center;
    margin: .4167rem;
    color: #2fccbf;
  }
}

.container {
  width: 7.2917rem;
  margin: 0 auto;
  .up_date{
    margin-bottom: .2604rem;
  }
  span{
    font-size: 14px;
    line-height: .1563rem;
  }
  .tit{
    display: block;
    margin: .1042rem 0;
  }
}

.ul_1 {
  margin-top: .2604rem;
  list-style-type: upper-alpha;
  margin-left: -1em;
  line-height: .1823rem;
  font-size: 20px;
  .ul_2 {
    font-size: 14px;
    list-style-type: lower-alpha;
    text-align: left;
    line-height: .1302rem;
    padding-left: .026rem;
    margin: .0521rem 0;
    .ul_3{
      font-size: 14px;
      list-style-type: disc;
      text-align: left;
      line-height: .1302rem;
      padding-left: .0521rem;
      margin: .0521rem 0;
    }
  }
}
.up_tit{
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 50px;
}
.statement{
  font-size: 18px;
  line-height: 30px;
}
.box_logo{
  width: 200px;
  height: auto;
  position: relative;
  left: 2.0313rem;
  top: .5729rem;
}
@media (max-width: 1000px) {
  .box_logo{
    width: 80px;
    height: auto;
    position: relative;
    left: .1042rem;
    top: 30px;
  }
  .container{
    h1{
      font-size: 24px;
    }
    .tit{
      font-size: 1em;
      line-height: 30px;
    }
    .up_tit{
      font-size: 14px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }
  .ul_1 {
    font-size: 10px;
    strong{
      line-height:20px;
    }
    .ul_2{
      line-height: 30px;
      padding-left: .0521rem;
      .ul_3{
        line-height: 30px;
        padding-left: .0521rem;
      }
    }
  }
}

</style>