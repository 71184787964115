//定义一个立即执行的匿名函数，传入两个参数，window 和 lib
(function (win, lib) {
	var doc = win.document //获取全局对象的 document 对象
	var docEl = doc.documentElement //获取文档的根元素
	var metaEl = doc.querySelector('meta[name="viewport"]')
	var flexibleEl = doc.querySelector('meta[name="flexible"]')
	var dpr = 0 //存储设备像素比
	var scale = 0 //存储缩放比例
	var tid //存储定时器的标识
	//定义全局对象 flexible，如果已经存在则使用现有对象，否则创建一个空对象。
	var flexible = lib.flexible || (lib.flexible = {})
//判断是否存在名为 “viewport” 的 meta 元素，如果存在，则从其 content 属性中提取初始缩放比例，
// 并计算设备像素比；如果不存在，则判断是否存在名为 “flexible” 的 meta 元素，如果存在，则从其 content 属性中提取初始像素比例和最大像素比例。
	if (metaEl) {
		var match = metaEl.getAttribute('content').match(/initial-scale=([d.]+)/)
		if (match) {
			scale = parseFloat(match[1])
			dpr = parseInt(1 / scale)
		}
	} else if (flexibleEl) {
		var content = flexibleEl.getAttribute('content')
		if (content) {
			var initialDpr = content.match(/initial-dpr=([d.]+)/)
			var maximumDpr = content.match(/maximum-dpr=([d.]+)/)
			if (initialDpr) {
				dpr = parseFloat(initialDpr[1])
				scale = parseFloat((1 / dpr).toFixed(2))
			}
			if (maximumDpr) {
				dpr = parseFloat(maximumDpr[1])
				scale = parseFloat((1 / dpr).toFixed(2))
			}
		}
	}
	//如果无法通过已有的 meta 元素获取设备像素比和缩放比例，则根据设备类型和像素比来设置默认值。
	if (!dpr && !scale) {
		// var isAndroid = win.navigator.appVersion.match(/android/gi);
		var isIPhone = win.navigator.appVersion.match(/iphone/gi)
		var devicePixelRatio = win.devicePixelRatio
		if (isIPhone) {
			// iOS下，对于2和3的屏，用2倍的方案，其余的用1倍方案
			if (devicePixelRatio >= 3 && (!dpr || dpr >= 3)) {
				dpr = 3
			} else if (devicePixelRatio >= 2 && (!dpr || dpr >= 2)) {
				dpr = 2
			} else {
				dpr = 1
			}
		} else {
			// 其他设备下，仍旧使用1倍的方案
			dpr = 1
		}
		scale = 1 / dpr
	}
 
	docEl.setAttribute('data-dpr', dpr)
	if (!metaEl) {
		//将设备像素比保存在根元素的 data-dpr 属性中
		metaEl = doc.createElement('meta')
		metaEl.setAttribute('name', 'viewport')
		metaEl.setAttribute(
			'content',
			'initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale + ', user-scalable=no'
		)
		if (docEl.firstElementChild) {
			docEl.firstElementChild.appendChild(metaEl)
		} else {
			var wrap = doc.createElement('div')
			wrap.appendChild(metaEl)
			doc.write(wrap.innerHTML)
		}
	}
	//定义函数 refreshRem，用于根据屏幕宽度计算并设置根元素的字体大小
	function refreshRem() {
		var width = docEl.getBoundingClientRect().width
		if (width / dpr > 1920) {
			// 这个位置划重点 1920是设计稿的大小 如果设计稿是750 那么就需要将1920替换成750
			width = (docEl.clientWidth / 1920) * 1920
		}
		var rem = width / 10
		docEl.style.fontSize = rem + 'px'
		flexible.rem = win.rem = rem
	}
	//监听窗口的 resize 事件，延时 300 毫秒执行 refreshRem 函数。
	win.addEventListener(
		'resize',
		function () {
			clearTimeout(tid)
			tid = setTimeout(refreshRem, 300)
		},
		false
	)
	//监听页面显示事件，如果页面被缓存并重新显示，则延时 300 毫秒执行 refreshRem 函数。
	win.addEventListener(
		'pageshow',
		function (e) {
			if (e.persisted) {
				clearTimeout(tid)
				tid = setTimeout(refreshRem, 300)
			}
		},
		false
	)
	//根据文档的加载状态设置根元素的字体大小
	if (doc.readyState === 'complete') {
		doc.body.style.fontSize = 12 * dpr + 'px'
	} else {
		doc.addEventListener(
			'DOMContentLoaded',
			function () {
				doc.body.style.fontSize = 12 * dpr + 'px'
			},
			false
		)
	}
 
	refreshRem()
 
	flexible.dpr = win.dpr = dpr
	flexible.refreshRem = refreshRem
	// 定义 rem2px 和 px2rem 方法，用于将 rem 和 px 之间进行转换。
	flexible.rem2px = function (d) {
		var val = parseFloat(d) * this.rem
		if (typeof d === 'string' && d.match(/rem$/)) {
			val += 'px'
		}
		return val
	}
	flexible.px2rem = function (d) {
		var val = parseFloat(d) / this.rem
		if (typeof d === 'string' && d.match(/px$/)) {
			val += 'rem'
		}
		return val
	}
})(window, window['lib'] || (window['lib'] = {}))