<template>
    <div class="foot">
      <div class="footer">
        <div class="container">
          <div class="row">
            <div class="arrange1">
              <div class="footer_left">
                <img class="minlogo" src="@/assets/image/top-logo.png" alt="">
                <br>
                <span>
                  DARE TO DREAM <br>
                  we thrive on collaboration
                </span>
              </div>
            </div>
            <div class="arrange2">
              <div class="footer_center">
                <div>
                  <h5 style="font-size: .1042rem; margin-bottom: .125rem;">SUNGARD BI-TECH INC.</h5>
                  <ul>
                    <li><router-link :to="$route.name.includes('plus')?'/plus/home':'/home'">Home</router-link></li>
                    <li><router-link :to="$route.name.includes('plus')?'/plus/aboutus':'/aboutus'">About</router-link></li>
                    <li><router-link :to="$route.name.includes('plus')?'/plus/game':'/game'">Game</router-link></li>
                    <li><router-link :to="$route.name.includes('plus')?'/plus/privacy.html':'/privacy.html'">Privacy Policy</router-link></li>
                    <li><router-link :to="$route.name.includes('plus')?'/plus/terms.html':'/terms.html'">Terms and Conditions</router-link></li>
                    <li><router-link v-if="$route.name.includes('plus')" to='/plus/rgpolicy.html'>Responsible Gameplay Policy</router-link></li>
                    <li><router-link v-if="$route.name.includes('plus')" to='/plus/gast.html'>Game Statement</router-link></li>
                    <li><router-link v-if="$route.name.includes('plus')" to="/plus/sweeps.html">Sweeps Rules</router-link></li>
                  </ul>
                </div>
                <div>
                  <h5 style="font-size: .1042rem; margin-bottom: .125rem;">Support</h5>
                  <ul>
                    <li>
                      <router-link class="list-group-item" active-class="active" :to="$route.name.includes('plus')?'/plus/careed':'/careed'" style="text-decoration: none;">Career</router-link>
                    </li>
                    <li>
                      <router-link class="list-group-item" active-class="active" :to="$route.name.includes('plus')?'/plus/contact':'/contact'" style="text-decoration: none;">contact</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="arrange3">
              <div class="footer_right">
                <h5 style="font-size: .1042rem; margin-bottom: .125rem;">Subscribe to Our eNewsletters</h5>
                <ul>
                  <li style="display: flex; justify-content: center;">
                    <el-input v-model="input" placeholder="" style="width: 1.042rem;"></el-input>
                    <el-button
                      style="position: relative; left: -0.0104rem; background-color: #853125; color: #Ed9A21; border: 0;">Register
                      <i class="el-icon-right" /></el-button>
                  </li>
                  <li><img class="minlogo" src="@/assets/image/F.jpeg" style="width: 0.1823rem; height: 0.1823rem; margin-top: 0.0885rem;" /></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Header',
    mounted(){
      console.log(this.$router.name);
    }
}
</script>

<style lang="scss" scoped>
.foot {
    width: 100%;
    background-color: #ED9A21;
}

.footer {
    padding-top: .5208rem;
    width: 8.8542rem;
    margin: 0 auto;
}

.row {
    display: flex;
}

.arrange1 {
    width: 2.2135rem;
    height: 1.3333rem;
}

.footer_left {
    font-size: .0833rem;
    color: #172532;
    font-family: 'SVN-Avant-book';

    img {
        margin-bottom: .0833rem;
    }

}

.arrange2 {
    margin-right: .4167rem;
}

.footer_center {
    display: flex;

    div {
        margin-right: .4167rem;

        ul {
            list-style-type: none;
            padding-left: 0;
            li {
                margin: 0 0 .0521rem;
                font-size: .0833rem;
                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }
}

.footer_right {
    padding: 0 .0833rem;
    margin-right: .4167rem;

    ul {
        list-style-type: none;
        padding-left: 0;
        li {
            margin: 0 0 .0521rem;

            a {
                text-decoration: none;
                color: #000;
            }
        }
    }
}
.minlogo{
  width:0.7813rem;
  height:0.2912rem;
}
</style>