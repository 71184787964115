<template>
    <div>
        <Header :message="'/careed'"></Header>
        <div class="careed-container">
            <div class="box-up  wow animate__animated animate__rollIn">
                <div class="box-head">
                    <h2 class="career">{{ career }}</h2>
                    <div class="box-row">
                        <div>
                            <span>{{ Copywriting1_1 }}<br>{{ Copywriting1_2 }}</span>
                        </div>
                        <div></div>
                        <div>
                            <h3>{{ Copywriting2 }}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-content wow animate__animated animate__zoomInDown">
                <div class="content-title">
                    <h6>CAREER OPPORTUNITIES</h6>
                </div>
                <div class="content-menu">
                    <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" background-color="#000" text-color="#fff"
                        active-text-color="#ED9A21">
                        <el-menu-item index="1" @click="editActiveIndex('1')">
                            <i class="el-icon-caret-right"></i>
                            <span slot="title">ALL</span>
                        </el-menu-item>
                        <el-menu-item index="2" @click="editActiveIndex('2')">
                            <i class="el-icon-caret-right"></i>
                            <span slot="title">MARKETING</span>
                        </el-menu-item>
                        <el-menu-item index="3" @click="editActiveIndex('3')">
                            <i class="el-icon-caret-right"></i>
                            <span slot="title">DEVELOPERS</span>
                        </el-menu-item>
                    </el-menu>
                    <el-table class="custom-table" :data="tableData" height="500" 
                    :header-cell-style="addTitleClass" 
                    :cell-style="addClass"
                    border style="width: 100%">
                        <el-table-column class-name="table-column1" prop="vacancies" label="VACANCIES" width="210">
                        </el-table-column>
                        <el-table-column class-name="table-column2" prop="quantity" label="QUANTITY" width="200">
                        </el-table-column>
                        <el-table-column class-name="table-column3" prop="deadline" label="THE DEADLINE FOR SUBMISSION">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="fan-page">
                <a href="https://www.facebook.com" target="_blank">
                    <i class="iconfont icon-lianshu"></i>
                </a>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Career',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            activeIndex:'1',
            career:'CAREER',
            isUpdate:true,
            tableHeaderColor:"backgroundColor:'#000 !important'",
            Copywriting1_1:'HE CRADLE OF GAMING INNOVATION',
            Copywriting1_2:'"BORN AND RAISED"',
            Copywriting2:'Elevate Your Play',
            tableData:[],
            MARKETING:[
                {
                    vacancies: 'SALES REPRESENTATIVE',// 市场营销
                    quantity: '3',
                    deadline: '2024-05-03'
                },],
            DEVELOPERS:[
                {
                    vacancies: 'Software Developer Intern',
                    quantity: '3',
                    deadline: '2024-05-03'
                },
                {
                    vacancies: 'H5 front-end development',
                    quantity: '1',
                    deadline: '2024-03-20'
                },
                
            ],
        };
    },
    mounted() {
        this.tableData = [...this.MARKETING,...this.DEVELOPERS];
    },

    methods: {
        addClass() {
          return 'background: #000;border:none; color: #fff; text-align: center;';
        },
        addTitleClass(){
            return "background: #000; border:none;text-align: center; font-size: 0.095rem; color:#ED9A21;"
        },
        editActiveIndex(num){
            this.activeIndex = num;
            if (this.activeIndex == 1) {
                this.tableData = [...this.MARKETING,...this.DEVELOPERS];
            }else if (this.activeIndex == 2) {
                this.tableData = this.MARKETING;
            }else if(this.activeIndex == 3){
                this.tableData = this.DEVELOPERS;
            }
        },
    },
   
};  
</script>

<style lang="scss" scoped>
@import url(@/fontCss/fontCss.css);
.careed-container{
    padding: 1.0417rem 0 0;
}
.box-up{
    width: 8.6980rem;
    margin: 0 auto;
    
}
.career{
    color: #fff;
    font-size: .4167rem;
    margin: 0 0 .0417rem;
}
.box-row{
    display: flex;
    position: relative;
    padding: 0 0 .3333rem;
}
.box-row>div:first-child>span{
    color: #fff;
}
.box-row>div:nth-child(2){
    position: absolute;
    bottom: .3333rem;
    left: 1.5625rem;
    width: 6.9583rem;
    height: .0052rem;
    background: rgba(255, 255, 255, 0.3215686275);
}
.box-row>div:last-child>h3{
    color: #ED9A21;
    position: absolute;
    right: .5208rem;
    bottom: .3646rem;
    font-size: .2813rem;
}
.fan-page{
    width: .5rem;
    height: .3333rem;
    border: .0052rem solid #fff;
    background-color: #ED9A21;
    position: fixed;
    top: 1.4583rem;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    a{
        text-decoration: none;
        i{
            font-size: .1875rem;
            color: #853E25;
        }
    }
}
.box-content{
    width: 100%;
    height: 100%;
    background-color: #000;
    padding: 0 1.5625rem 0 .5208rem;
    padding-top: .2604rem;
    .content-menu{
        display: flex;
    }
}
.content-title{
    margin: 0 3.0469rem .375rem;
    text-align: center;
    h6{
        color: #ED9A21;
        font-size: .0938rem;
        padding: 0 0 .1042rem;
        margin: 0 0 .1042rem;
        border-bottom: .0052rem solid rgba(255, 255, 255, 0.3215686275);
        font-family: 'SVN-Avant-book';
    }
}
/** 导航栏样式修改 */
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: #ED9A21 !important;
  color: #ffffffc9 !important;
}
.el-menu-vertical-demo,.el-menu{
  
    border-right: 0;
    width: 1.5625rem;
    padding-top: .2604rem;
    margin-right: 1.0417rem;
}
.el-menu-item{
    font-size: .1302rem;
}
.custom-table {
  background-color: #000; 
  border: none;
}
.el-table--border::after, .el-table--group::after, .el-table::before {
    content: none !important;
}

.custom-table{
    height: 2.6042rem;
}
.table-column1{
    width: 1.0938rem;
    font-size: .0938rem;
}
.table-column2{
    width: 1.0417rem;
    font-size: .0938rem;
}
.table-column3{
    width: auto;
    font-size: .0938rem;
}
</style>