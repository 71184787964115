<template>
  <div style="background-color: #172532; width: 100%; height: 100%;">
    <Header :message="'/home'"></Header>

    <!-- 主体 -->
    <div class="body">
      <div style="width: 8.8542rem; margin:0 auto; display: flex; justify-content: space-between;padding:.5833rem 0 0;">
        <div class="body-div-left wow animate__animated animate__fadeInUp" style="margin-right: .3125rem; width: 3.6302rem; ">
          <div>
            <h1 style="color: #fff;font-size: .3333rem; margin: 0 0 .1667rem;">SUNGARD BI-TECH INC.</h1>
          </div>
          <div>
            <p style="color: #b3b3b3; font-size: .0833rem;">DARE TO DREAM, WE THRIVE ON COLLABORATION</p>
          </div>
          <div class="body-div-left-bottom">
            <div class="after1">
              <div class="number"> 50+ </div>
              <span>Games launched</span>
            </div>
            <div class="after1">
              <div class="number"> 10M+ </div>
              <span>Downloads</span>
            </div>
            <div class="after1">
              <div class="number"> $1M+ </div>
              <span>IAP</span>
            </div>
            <div class="after1">
              <div class="number"> 100+ </div>
              <span>Staffs</span>
            </div>
          </div>
        </div>
        <div class="wow animate__animated animate__fadeInLeft">
          <img style="width: 4.4271rem; height: 3.0724rem; padding: .5833rem 0 0;" src="@/assets/image/GameShow-2.png">
        </div>
      </div>

      <!-- 轮播图-游戏展示区域 -->
      <div class="featured"  data-wow-duration="2s" style="display: flex;">
        <h3 style="font-size: .25rem; color: #fff;">Featured Game</h3>
        <a href=""><span>View all</span><i style="display: block; border-radius: 100; color: #Ed9A21;"
            class="el-icon-right"></i></a>
      </div>
      <div class="carousel  wow animate__animated animate__zoomInUp" data-wow-offset="300" data-wow-delay="2s" style="width: 8.8542rem; margin:auto; padding: .3646rem 0;">
        <el-carousel :interval="5000" arrow="always" height="4.2135rem">
          <el-carousel-item v-for="item in images" :key="item.url">
            <img :src="item.url" class="bannerimg" style="width: 100%; height: 100%; object-fit: cover" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 游戏发布区域 -->
      <div class="home-game-publish wow animate__animated animate__fadeInUp"  data-wow-offset="300">
        <div class="home-game-publish-left">
          <div>
            <h3 style="color: #fff;font-size: 0.2333rem; margin: 0 0 .0833rem;">Game Publish</h3>
          </div>
          <div>
            <h6 style="color: #Ed9A21; font-size: .0938rem;margin: .125rem 0;">YOUR ULTIMATE ALLY IN BRIDGING WORLDS</h6>
          </div>
          <div>
            <article style="color: #d7caca; font-size: .0833rem; font-family: 'SVN-Avant-book';">
              Are you a solo developer or a boutique studio dreaming of global acclaim? Do you seek for your creation to captivate audiences and your vision to resonate far and wide? SUNGARD BI-TECH INC. stands ready to elevate your journey in Publishing, Mentorship, Marketing - Monetization, and Live Operations, ensuring your endeavors not only shine but reign supreme.
            </article>
          </div>
          <div class="body-div-left-bottom">
            <div class="after1">
              <div class="number"> 50+ </div>
              <span>Games launched</span>
            </div>
            <div class="after1">
              <div class="number"> 10M+ </div>
              <span>Downloads</span>
            </div>
          </div>
        </div>
        <div class="home-game-publish-right">
          <img src="@/assets/image/Game Publish.png" alt="">
        </div>
      </div>
      <!-- 无限滚动卡片区域 -->
      <!-- <div style="padding-bottom: .5208rem;">
        <vue-seamless-scroll :data="images2" :class-option="optionLeft" class="seamless-warp2">
          <ul class="item">
            <li v-for="item in images2" :key="item">
              <img class="scrollimg" :src="item.url" alt="">
            </li>
          </ul>
        </vue-seamless-scroll>
      </div> -->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      images: [
        { url: require("@/assets/image/GameShow-1.png") },
        { url: require("@/assets/image/GameShow-2.png") },
        { url: require("@/assets/image/GameShow-3.png") },
      ],
      images2: [
        { url: require("@/assets/image/pangle.png") },
        { url: require("@/assets/image/unity.png") },
        { url: require("@/assets/image/vungle.png") },
        { url: require("@/assets/image/tapjoy.png") },
        { url: require("@/assets/image/ggads.png") },
        { url: require("@/assets/image/ggadmob.png") },
        { url: require("@/assets/image/unity.png") },
      ]
    }
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// 主体样式区
.body {
  width: 100%;
  background-color: #172532;
}

.body-div-left {
  position: relative;
  padding: .5833rem 0 0;
  div{
    position: relative;
    z-index: 9;
  }
}
.body-div-left::after{
  content: '';
  width: 100%;
  height: 100%;
  // background-image: url(../../assets/image/Left-introduce-background.png);
  // background-size: cover;
  // background-size: contain;
  background-repeat: no-repeat; // 不重复
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1 !important; /* 父元素内容透明度 */
}
.body-div-left-bottom {
  display: flex;
  margin-top: .25rem;

  div {
    height: .3646rem;
    width: 1.0417rem;
    padding-right: .25rem;
    position: relative;
    top: 0;
    left: 0;

    .number {
      //var(--color-yellow)
      color: #ED9A21;
      font-size: .25rem;
      font-family: "SVN-Avant-book";
      font-weight: 600;
    }

    span {
      color: #fff;
    }
  }

  .after1::after {
    content: "";
    position: absolute;
    transform: translate(0, -50%);
    top: 60%;
    right: .1823rem;
    width: .0052rem;
    height: 70%;
    background: rgba(255, 255, 255, 0.2);
  }
}

.featured {
  width: 9.1667rem;
  margin: 0 auto;
  padding-top: .3646rem;
  display: flex;
  justify-content: space-between;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    height: .1563rem;

    span {
      color: #ED9A21;
      margin-right: .026rem;
    }

    i {
      display: block;
      border: .0156rem solid #ED9A21;
      border-radius: .2604rem;
      font-size: .125rem;
    }
  }
}

// 轮播图-游戏展示区域样式
.el-carousel__item h3 {
  color: #475669;
  font-size: .0938rem;
  opacity: 0.75;
  line-height: 1.5625rem;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

// 游戏发布样式区域
.home-game-publish {
  width: 9.1667rem;
  margin: 0 auto;
  display: flex;
  padding: .2604rem 0 .2604rem;
}

.home-game-publish-left {
  width: 4.4271rem;

}

.home-game-publish-right {
  width: .3646rem;
  img{
    height:2.0833rem;
    width:3.8438rem;
  }
}



.seamless-warp2 {
  overflow: hidden;
  height: .625rem;
  width: 7.8125rem;
  margin: 0 auto;
  padding: .2083rem;
  background-color: #000;
  border-radius: .0521rem;
  border: .0052rem solid #ED9A21;

  ul.item {
    width: 7.0313rem;
    list-style: none;

    li {
      float: left;
      margin-right: .4688rem;
    }
  }
}
.scrollimg{
  width: .4167rem;
  height: auto ;
}
</style>