<template>
  <div class="aboutus">
    <Header :message="'/aboutus'"></Header>
    <div class="container">
      <div class="studio wow animate__animated animate__fadeInLeft">
        <div class="studio-left">
          <h6>{{ studioName }}</h6>
          <article class="studio-left-article">
            <p>{{ studioP1 }}</p>
            <p>{{ studioP2 }}</p>
          </article>
          <div class="studio-left-introduce">
            <div class="studio-left-introduce-row">
              <p>S T A F F S</p>
              <div class="number">100+</div>
            </div>
            <div class="studio-left-introduce-row">
              <p>GAMES LAUNCHED</p>
              <div class="number">50+</div>
            </div>
            <div class="studio-left-introduce-row">
              <p>DOWNLOADS</p>
              <div class="number">10M+</div>
            </div>
          </div>
        </div>
        <div class="studio-right">
          <div class="studio-right-img">
            <div class="background" style="background-color: #ED9A21;"></div>
            <img src="@/assets/image/falcon-tap-the1.png" alt="">
          </div>
        </div>
      </div>

      <div class="studio wow animate__animated animate__fadeInRight">

        <div class="studio-right">
          <div class="studio-right-img">
            <div class="background" style="background-color: #ED9A21;"></div>
            <img src="@/assets/image/falcon-tap-the2.png" alt="">
          </div>
        </div>
        <div class="studio-left" style="padding-top: .4167rem;">
          <h6>{{ visionName }}</h6>
          <article class="studio-left-article vision1">{{ vision1 }}</article>
          <article class="studio-left-article vision2">{{ vision2 }}</article>
          <article class="studio-left-article vision3">{{ vision3 }}</article>
          <article class="studio-left-article vision4">{{ vision4 }}</article>
        </div>
      </div>

    <!-- <div class="container-bot"></div> -->
      <div class="covervalue">
        <div class="bgimg"></div>
        <h3 class="covervalue-title">CORE VALUE</h3>
        <div class="cover-value">
          <img src="@/assets/image/1538x1538(1).png" alt="">
          <img src="@/assets/image/1538x1538(2).png" alt="">
          <img src="@/assets/image/1538x1538(3).png" alt="">
          <img src="@/assets/image/1538x1538(4).png" alt="">
          <img src="@/assets/image/1538x1538(5).png" alt="">
        </div>
      </div>
      
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  name: 'AboutUs',
  components: {
    Header,
    Footer,
  },
  data(){
    return{
      studioName:'SUNGARD BI-TECH INC.',
      studioP1:'SUNGARD BI-TECH INC. is an ambitiously expanding game development firm, holding the distinction of being among the trailblazers in the United States and Canada to achieve game revenues surpassing 1 million USD in-app, and boasting several titles with over 100 million downloads across Google Play and the Apple Store.',
      studioP2:'We envision ourselves as forerunners in the gaming sphere, eager to collaborate with game creators within and beyond our borders, dedicated to propelling games of North American origin onto the global stage with vigor and vision.',
      visionName:'VISION AND MISSION',
      vision1:'VISION 555',
      vision2:'In the span of the next five years, we set our sights high: to forge a gaming masterpiece that eclipses $5 million in in-app purchases, while catapulting our annual revenue to a staggering $500 million. This ambitious journey is not just about numerical targets; it’s a quest for innovation, excellence, and global recognition in the gaming world.',
      vision3:'MISSION',
      vision4:"SUNGARD BI-TECH INC. is devoted to leading the charge in revolutionizing the in-app game production landscape in North America. With our roots deeply planted in creativity and innovation, we are on a mission to showcase the boundless potential of North American game developers on the global stage. Our journey is fueled by unwavering perseverance, a burning passion for gaming, and a relentless pursuit of excellence. Together, we are not just creating games; we are crafting legacies that resonate with gamers around the world.",
    }
  }
}
</script>

<style lang="scss" scoped>

.aboutus{
  width: 100%;
  height: 100%;
  background-color: #172532;
}
.container{
  width: 8.8542rem;
  margin: 0 auto;
  position: relative;
  top: 0;
  left: 0;
}
.studio{
  padding: .5208rem 0 0 0;
  display: flex;
}
.studio-left,.studio-right{
  width: 4.4271rem;
}
.studio-left{
  height: 2.5rem;
  padding-left: .25rem;
  h6{
    color: #ED9A21;
    position: relative;
    margin: 0 0 .1563rem;
    padding: 0 0 0 .4167rem;
    font-size: .1042rem;
  }
  h6::after{
    content: "";
    color: #ED9A21;
    position: absolute;
    width: .4167rem;
    height: .0052rem;
    background: #ED9A21;
    transform: translate(0, -50%);
    top: 50%;
    left: 0;
  }
}
.studio-left-article{
  width: 3.5521rem;
  font-size: .0833rem;
  margin: 0 0 0 .4688rem;
  p{
    margin: 0 0 .0833rem;
    font-family:'SVN-Avant-book';
    color: #c4bcbccb;
  }
}
.studio-left-introduce{
    padding-left: .4688rem;
    margin-top: .3333rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -0.0781rem;
    margin-left: -0.0781rem;
}
.studio-left-introduce-row{
  color: #fff;
  padding: 0 .0833rem;
  width: 1.8542rem;
}
.number{
  font-size: .3333rem;
    font-family: "SVN-Avant-book";
    line-height: 1;
    margin-top: 0.2rem;
    margin-bottom: .1667rem;
    font-weight: bold;
}
.studio-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.studio-right-img{
  position: relative;
  padding: .0313rem;
  color: #fff;
  img{
    width: 100%;
    height: auto;
    min-height: auto;
    max-height: 2.0833rem;
    border-radius: .026rem;
  }
}
.studio-right-img::before {
    content: "";
    position: absolute;
    width: calc(100% - .0469rem);
    height: calc(100% - .0469rem);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    -webkit-clip-path: polygon(0 0, 90% 0, 100% .1823rem, 100% 100%, 10% 100%, 0 90%, 0 0);
    clip-path: polygon(0 0, 90% 0, 100% .1823rem, 100% 100%, 10% 100%, 0 90%, 0 0);
    background: #ED9A21;
    z-index: -1;
}
.studio-right-img::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    clip-path: polygon(0 0, 90% 0, 100% .1823rem, 100% 100%, 10% 100%, 0 90%, 0 0);
    background: #ED9A21;
    z-index: -1;
}
.vision1{
  margin: 0 0 .0833rem .4688rem;
  color: #c4bcbccb;
}
.vision2{
  margin: 0 0 0 .4688rem;
  color: #c4bcbccb;
}
.vision3{
  margin: .3333rem 0 .0833rem .4688rem;
  color: #c4bcbccb;
}
.vision4{
  margin: 0 0 0 .4688rem;
  color: #c4bcbccb;
}

.covervalue{
  width: 8.8542rem;
  margin: .5208rem auto;
  padding: .3906rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.covervalue::after{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
  top: 0;
  background: linear-gradient(0deg, rgba(23, 37, 50, 0.85), rgba(23, 37, 50, 0.85));
  z-index: -1;
}
.covervalue-title{
  font-size: .2333rem;
  font-family: "SVN-Gotham-bold";
  margin: 0 0 .25rem;
  color: #fff;
  z-index: 9;
}
.cover-value{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  z-index: 9;
  img{
    width: 1.6526rem;
    height: 1.6526rem;
    z-index: 9;
  }
}
.bgimg{
  position: absolute;
  width: 100vw;
  height: 2.6042rem;
  bottom: .1302rem;
  background-image: url('@/assets/image/bj-1.png');  
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
}
.bgimg::after{
  content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
    top: 0;
    // background: linear-gradient(0deg, rgba(23, 37, 50, 0.85), rgba(23, 37, 50, 0.85)); 
    opacity: .5;
    z-index: 0;
}
</style>