//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
Vue.use(VueRouter);

//引入路由组件
import Home from '@/view/home/index.vue'
import AboutUs from '@/view/aboutus/index.vue'
import Game from '@/view/game/index.vue'
import Career from '@/view/career/index.vue'
import Contact from '@/view/contact/index.vue'
import PrivacyPolicy from '@/view/privacypolicy/index.vue'
import TermsAndConditions from '@/view/termsandconditions/index.vue'
import ResponsibleGameplayPolicy from '@/view/responsiblegameplaypolicy/index.vue'
import GameStatement from '@/view/gamestatement/index.vue'
import SweepsRules from '@/view/sweepsrules/index.vue'



const router = new VueRouter({
    mode: 'history',
    //配置路由
    routes:[
        {
            name:'home',
            path: '/home',
            component: Home,
        },
        {
            name:'aboutus',
            path: '/aboutus',
            component: AboutUs,
        },
        {
            name:'game',
            path: '/game',
            component: Game, 
        },
        {
            name:'careed',
            path: '/careed',
            component: Career,
        },
        {
            name:'contact',
            path: '/contact',
            component: Contact,
        },
        {
            name:'privacypolicy',
            path: '/privacy.html',
            component: PrivacyPolicy,
        },
        {
            name:'termsandconditions',
            path: '/terms.html',
            component: TermsAndConditions,
        },{
            name:'plushome',
            path: '/plus/home',
            component: Home,
        },
        {
            name:'plusaboutus',
            path: '/plus/aboutus',
            component: AboutUs,
        },
        {
            name:'plusgame',
            path: '/plus/game',
            component: Game, 
        },
        {
            name:'pluscareed',
            path: '/plus/careed',
            component: Career,
        },
        {
            name:'pluscontact',
            path: '/plus/contact',
            component: Contact,
        },
        {
            name:'plusprivacypolicy',
            path: '/plus/privacy.html',
            component: PrivacyPolicy,
        },
        {
            name:'plustermsandconditions',
            path: '/plus/terms.html',
            component: TermsAndConditions,
        },
        {
            name:'plusresponsiblegameplaypolicy',
            path: '/plus/rgpolicy.html',
            component: ResponsibleGameplayPolicy,
        },
        {
            name:'plusgamestatement',
            path: '/plus/gast.html',
            component: GameStatement,
        },
        {
            name:'plussweepsrules',
            path: '/plus/sweeps.html',
            component: SweepsRules,
        },
        {
            path: '/plus/*',
            redirect: '/plus/home',
        },
        {
            path: '*',
            redirect: '/home',
        }
    ]
  })


export default router
